<template>
<div class="overview">

    <div class="basic-1 cards-1">
        <div class="container">
            <div class="row">

                <div class="col-lg-12 ">
                    <h2>Login</h2>
                    <br>
                    <div class="testimonial-item login">

                        <input type="text" placeholder="Enter License Key" name="ukey" v-model="key" required>
                        <button type="submit" v-on:click="login();">Login</button>

                    </div>
                    <br>
                    <br><a href="https://gumroad.com/l/eHhaGG">Get License Key</a>
                </div>

            </div>
        </div>
    </div>

</div>
</template>

<script>
import router from '../router'

var axios = require('axios');

export default {
    components: {},
    name: 'HelloWorld',
    props: {
        msg: String
    },
    computed: {
        formattedElapsedTime() {
            const date = new Date(null);
            //date.setSeconds(this.elapsedTime / 1000);
            date.setMilliseconds(this.elapsedTime);
            const utc = date.toISOString();
            return utc.substr(utc.indexOf(".") - 2, 5) + 's';
        },
    },
    created: function () {
         
    },
    methods: {
        login: function () {
            console.log(this.key);
            axios.post("https://api.gumroad.com/v2/licenses/verify?product_permalink=eHhaGG&license_key=" + this.key)
                .then(response => {
                    console.log(response.data)

                    if (response.data['success'] === true) {
                        console.log('Success');
                        this.$cookie.set('key', this.key, 1);
                        router.replace("/home");
                    }
                })
                .catch(err => {
                    console.log(err.response.data);
                })

        }
    },
    data: function () {
        return {
            elapsedTime: 1,
            totalTime: 1,
            averageTime: 1,
            timer: undefined,
            answer: "",
            score: 0,
            index: 0,
            data: [{}, {}],
            title: '',
            exercise: [],
            key: '',
            api: []
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';

.overview {
    width: 100%;
    position: absolute;
    margin-top: 6%;   ; 
}

.login {
    width: 50%;
    margin: 0 auto;
    border: 0px;
    box-shadow: 0 10px 25px rgba(0,0,0,0.05),0 20px 48px rgba(0,0,0,0.05),0 1px 4px rgba(0,0,0,0.1);
}

button {
    background-color: rgb(44, 151, 75);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

button:hover {
    opacity: 0.8;
}

form {
    border: 3px solid #f1f1f1;
}

input[type=text],
input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.btn.btn__default {
    color: black;
}

.above-heading {
    font-size: 12px;
    font-style: bold;
}

.hero-cta {
    color: white;
}

h2 {
    font-size: 4.2rem;
    line-height: 1.1;
    margin-bottom: 25px;
}

.above-heading {
    color: #5f4dee;
    font: 0.70.75rem/0.075rem "Open Sans", sans-serif;
    text-align: center;
}

h4 {
    margin: 41px 0 0;
}

ul {
    text-align: left;
}

#parent {
    width: 101%;
    height: 226px;
    display: block;
    position: relative;
}

#editor {
    position: absolute;
    /* Added */
    top: 1;
    right: 1;
    bottom: 1;
    left: 1;
    margin: 1 auto;
    border-radius: 9px;
}

.btn-solid-reg {
    cursor: pointer;
}

.score {
    font: 701 1rem/1.625rem "Open Sans", sans-serif;
    letter-spacing: 1.1px;
}

.divider {
    height: 2px;
    margin-top: 2.75rem;
    margin-bottom: 3rem;
    border: none;
    background-color: #ccd4df;
}

.box {
    display: block;
    max-width: 20rem;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-left: auto;
    border: 2px solid #ccd3df;
    border-radius: 1.375rem;
    ;
    display: inline-block;
    margin: 11px;
    padding: 16px;
}

.testimonial-item__avatar {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
    border: 0px;
}

:link {
    color: #0000EE;
}

:visited {
    color: #551A8B;
}
</style>
